export default date => {
  const now = new Date(date);
  let month = (now.getMonth() + 1).toString();
  if (month.length === 1) {
    month = '0' + month;
  }

  let day = now.getDate().toString();
  if (day.length === 1) {
    day = '0' + day;
  }

  return `${now.getFullYear()}-${month}-${day}`;
};
